'use client'

import {DPEuProjectNode, DPFullNode, DPGeneral} from 'types/DPTypes'
import {PropsWithChildren, createContext, useContext, useEffect} from 'react'

import {AdminTools} from 'layout/admin/AdminTools'
import {CookiehubContextProvider} from 'util/useCookiehub'
import {EuProjectsItemType} from 'euprojects/euprojects'
import {Locale} from 'util/locale'

type EuProjectType = DPEuProjectNode & {
	extra_eu_project?: {
		found: boolean
		project: EuProjectsItemType | null
	}
}

export type AppContextType = {
	general: DPGeneral
	lang: Locale
}

export type AppNodeContextType = {
	node: DPFullNode | EuProjectType | null
}

const AppNodeContext = createContext<AppNodeContextType>(null)

export const AppNodeContextProvider: React.FC<
	PropsWithChildren<AppNodeContextType>
> = ({node, children}) => {
	return (
		<AppNodeContext.Provider value={{node}}>
			{node && <AdminTools node={node} />}
			{children}
		</AppNodeContext.Provider>
	)
}

const AppContext = createContext<AppContextType | false>(false)

export const AppContextProvider: React.FC<
	PropsWithChildren<{general: DPGeneral; lang: Locale}>
> = ({children, general, lang}) => {
	useEffect(() => {
		if ('_debug' in general) console.info(general._debug)
	}, [general])
	return (
		<AppContext.Provider value={{general, lang}}>
			<CookiehubContextProvider>{children}</CookiehubContextProvider>
		</AppContext.Provider>
	)
}

export const useApp = () => {
	const result = useContext(AppContext)
	const nodeResult = useContext(AppNodeContext)
	if (!result)
		throw new Error(
			'Trying to use the useApp hook, but no AppContext is defined.'
		)
	return {
		general: result.general,
		lang: result.lang,
		node: nodeResult ? nodeResult.node : null
	}
}
